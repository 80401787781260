import { Controller } from "stimulus"
export default class extends Controller {
  
  
  connect() {
    let display = $('#slide-preview-display')
    display.css({cursor: 'pointer'})
    display.on('click', _.bind(this.click, this))
  }
  
  click(e) {
    let html = `<div 
                  class="
                    slide-preview-popup 
                    image-display 
                    image-display--${ this.data.get('aspect-ratio-mode') }" 
                >
                ${ this.data.get('url') }
                </div>`
                
    popup.open( html, 
      { 
        width: 1024, 
        height: 768, 
        closeButton: true, 
        escDismiss: true, 
        backgroundDismiss: true, 
        fitWindow: true, 
        noPadding: true 
      } 
    )
    
  }
  
  
  
}
