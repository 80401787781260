import { Controller } from "stimulus"
export default class extends Controller {
  static targets = [ "field", "icon", "text", "button" ]
  
  initialize() {
    this.defaultText = this.textTarget.innerHTML
    this.filename = null
    $(this.element).on('drop', this.drop)
    $(this.element).closest("form").on('reset', _.bind(this.reset, this))
  }
  
  change(e) {
    this.processFile() 
    this.updateButtonStatus()
  }
  
  reset(e) {
    // set a timeout to check after the form has been reset
    setTimeout(_.bind(
      function() {
        this.change(e)
      }, this
    ), 1);
  }
  
  prepareDrop(e) {
    e.preventDefault()
    $(this.element).addClass('upload-field--drop-ready')
    $(this.buttonTarget).removeClass('btn--blue-icon').addClass('btn--gray-icon')
    $(this.element).removeClass('upload-field--with-file')
    $(this.iconTarget).removeClass('fa-upload').addClass('fa-file')
    this.textTarget.innerHTML = 'Drop file...'
  }
  
  unPrepareDrop(e) {
    e.preventDefault()
    $(this.element).removeClass('upload-field--drop-ready')
    this.updateButtonStatus() 
  }
  
  dropFile(e) {
    e.preventDefault()
    e.stopPropagation()
    $(this.element).removeClass('upload-field--drop-ready')
    this.fieldTarget.files = e.dataTransfer.files;
    this.processFile()
    this.updateButtonStatus();
  }
  
  updateButtonStatus() {
    if (this.filename) {
      this.textTarget.innerHTML = this.filename
      $(this.buttonTarget).removeClass('btn--gray-icon').addClass('btn--blue-icon')
      $(this.element).addClass('upload-field--with-file')
      $(this.iconTarget).removeClass('fa-file').addClass('fa-upload')
    } else {
      this.textTarget.innerHTML = this.defaultText
      $(this.buttonTarget).removeClass('btn--blue-icon').addClass('btn--gray-icon')
      $(this.element).removeClass('upload-field--with-file')
      $(this.iconTarget).removeClass('fa-upload').addClass('fa-file')
    }
  }
  
  processFile() {
    if ( this.fieldTarget.files && this.fieldTarget.files.length > 0 ) {
      this.filename = this.fieldTarget.files[0].name
    } else {
      this.filename = null
    }
  }
  
  
}
