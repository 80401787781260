import 'mediaelement';
import 'mediaelement/build/mediaelementplayer.min.css';

if ($('.mejs__plsayer').length > 0) {
  $('.mejs__plsayer').mediaelementplayer({});  

  dispatch.bind("page:refresh-view", function() {
    $('.mejs__plsayer').mediaelementplayer({});
  })
  
}
